import { useRequest } from "ahooks";
import React, { useEffect } from "react";

import FormCheckbox from "../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../components/RefactoredForms/FormInput";
import documentTypeService from "../../../services/documentTypeService";
import SupervisorInfo from "./SupervisorInfo";

const SupervisorEditForm = ({ supervisor, onCancel }) => {
    const { data: documentTypes, run: loadDocumentTypes } = useRequest(documentTypeService.fetchAll, { manual: true });

    useEffect(() => {
        loadDocumentTypes({ per_page: 9999 });
    }, []);

    return (
        <>
            <SupervisorInfo
                referent_name={supervisor?.referent_name}
                referent_contact={supervisor?.referent_contact}
                supervisor={supervisor?.supervisor}
                onCancel={onCancel}
            />

            <div className={"grid xl:grid-cols-3 lg:grid-cols-2 gap-4"}>
                <FormGroup label='Referente aziendale' name='referent_name'>
                    <FormInput name='referent_name' />
                </FormGroup>
                <FormGroup label='Contatto referente aziendale' name='referent_contact'>
                    <FormInput name='referent_contact' />
                </FormGroup>
            </div>

            <FormDescription
                title='Tipologie documento'
                desc={`Seleziona le tipologie documento a cui ha accesso ${supervisor?.supervisor?.business_name}.`}
            />
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                {documentTypes?.data.map(dt => (
                    <FormCheckbox
                        key={dt.uuid}
                        id={dt.uuid}
                        name={"document_types"}
                        label={dt.name}
                        description={dt.description}
                        value={dt.id.toString()}
                    />
                ))}
            </div>
        </>
    );
};

export default SupervisorEditForm;
