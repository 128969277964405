import { useRequest } from "ahooks";
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";

import FormCheckbox from "../../../../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../../../../../components/RefactoredForms/FormSelect";
import companyService from "../../../../../../../../services/companyService";
import documentTypeService from "../../../../../../../../services/documentTypeService";
import SupervisorInfo from "../../../../../../../_forms/supervision/SupervisorInfo";
import { CompanyContext } from "../../../Company";

const SupervisorCreateForm = ({ setSelectedCompany, selectedCompany }) => {
    const { company } = useContext(CompanyContext);
    const { data: documentTypes, run: loadDocumentTypes } = useRequest(documentTypeService.fetchAll, { manual: true });
    const { loading: loadingCompany, runAsync: searchCompany } = useRequest(companyService.fetchAll, { manual: true });
    const [companyOptions, setCompanyOptions] = useState([]);
    const { setFieldValue, values, setValues } = useFormikContext();

    useEffect(() => {
        loadDocumentTypes({ per_page: 9999 });
    }, []);

    const handleCompanySelectionChange = value => {
        if (!!value) {
            searchCompany({
                filter: {
                    business_name: value,
                    can_manage_other_companies: true,
                },
            }).then(({ data }) => setCompanyOptions(data.filter(d => d.id !== company.id)));
        } else {
            setCompanyOptions([]);
        }
    };

    return (
        <>
            <FormDescription
                title={"Seleziona un professionista delegato"}
                desc={`Cerca e seleziona l'azienda che avrà accesso ai documenti di ${company?.business_name}.`}
            />

            {selectedCompany ? (
                <>
                    <SupervisorInfo
                        supervisor={selectedCompany}
                        onCancel={() => {
                            setSelectedCompany(null);
                            setValues({
                                ...values,
                                supervisor_id: "",
                                referent_contact: "",
                                referent_name: "",
                            });
                        }}
                    />
                    <div className={"grid xl:grid-cols-3 lg:grid-cols-2 gap-4"}>
                        <FormGroup label='Referente aziendale' name='referent_name'>
                            <FormInput name='referent_name' disabled={!!!selectedCompany} />
                        </FormGroup>
                        <FormGroup label='Contatto referente aziendale' name='referent_contact'>
                            <FormInput name='referent_contact' disabled={!!!selectedCompany} />
                        </FormGroup>
                    </div>

                    <FormDescription
                        title='Tipologie documento'
                        desc={`Seleziona le tipologie documento a cui ha accesso ${selectedCompany?.business_name}.`}
                    />

                    <FormGroup label='Tipologie documenti' name='document_types'>
                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            {documentTypes?.data.map(dt => (
                                <FormCheckbox
                                    key={dt.uuid}
                                    name={"document_types"}
                                    label={dt.name}
                                    disabled={!!!selectedCompany}
                                    description={dt.description}
                                    value={dt.id.toString()}
                                />
                            ))}
                        </div>
                    </FormGroup>
                </>
            ) : (
                <FormGroup label='Professionista delegato' name={"supervisor_id"} required>
                    <FormSelect
                        isSearchable
                        isClearable
                        isLoading={loadingCompany}
                        placeholder="Cerca un'azienda"
                        options={companyOptions}
                        getOptionLabel={e => e.business_name}
                        getOptionValue={e => e.id}
                        name='supervisor_id'
                        value={values.supervision_id}
                        onInputChange={value => handleCompanySelectionChange(value)}
                        onChange={company => {
                            setSelectedCompany(company);
                            setFieldValue("supervisor_id", company?.id || "");
                        }}
                    />
                </FormGroup>
            )}
        </>
    );
};

export default SupervisorCreateForm;
