import React from "react";

import PublicLayout from "../../components/layout/Public Layout/PublicLayout";
import publicCompanyService from "../../services/publicCompanyService";
import TokenAuth from "../../views/auth/TokenAuth/TokenAuth";
import Dashboard from "../../views/public/dashboard/Dashboard";
import TokenAuthGuard from "../TokenAuthGuard";

const publicRoutes = [
    {
        path: publicCompanyService.getPublicCompanyAuthUrl(":id"),
        element: (
            <TokenAuthGuard auth={false} pathResolver={publicCompanyService.getPublicCompanyDashboardUrl}>
                <TokenAuth />
            </TokenAuthGuard>
        ),
    },
    {
        path: "*",
        element: (
            <TokenAuthGuard auth={true} pathResolver={publicCompanyService.getPublicCompanyAuthUrl}>
                <PublicLayout />
            </TokenAuthGuard>
        ),
        children: [
            {
                path: "*",
                index: true,
                element: <Dashboard />,
            },
        ],
    },
];

export default publicRoutes;
