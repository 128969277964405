import { useRequest } from "ahooks";
import { useFormikContext } from "formik";
import React from "react";

import FormCheckbox from "../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../../components/RefactoredForms/FormSelect";
import documentCategoryService from "../../../../../services/documentCategoryService";

const DocumentTypeForm = () => {
    const { setFieldTouched, values, setValues } = useFormikContext();
    const { data: documentCategories } = useRequest(documentCategoryService.noPaginate);

    return (
        <div className='flex flex-col gap-4'>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='Nome' name='name' required>
                    <FormInput name='name' />
                </FormGroup>
                <FormGroup label='Descrizione' name='description' required>
                    <FormInput name='description' />
                </FormGroup>
                <FormGroup label='Categoria documento' name={"document_category_id"}>
                    <FormSelect
                        isClearable
                        placeholder='Seleziona una categoria'
                        value={values.document_category_id}
                        name='document_category_id'
                        onBlur={() => setFieldTouched("document_category_id")}
                        options={documentCategories}
                        onChange={value =>
                            setValues({
                                ...values,
                                document_category_id: value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup label='' name='can_be_uploaded_by_company'>
                    <FormCheckbox name='can_be_uploaded_by_company' label={"L'azienda può caricare questa tipologia di documento."} />
                </FormGroup>
            </div>
            <FormDescription
                title='Riconoscimento automatico file'
                desc="Per riconoscere in automatico il file è necessario specificare la parte iniziale del nome del file fino al primo '_' (underscore).
                                    Tutti i file che inizieranno con il prefisso specificato saranno automaticamente riconosciuti come dello stessa tipologia."
            />

            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='' name='is_regex_enabled'>
                    <FormCheckbox name='is_regex_enabled' label={"Abilita riconoscimento automatico"} />
                </FormGroup>
                <FormGroup
                    label='Nome allegato'
                    name='filename_regex'
                    required={values.is_regex_enabled}
                    info={values.is_regex_enabled ? `Es. ${values.filename_regex}_nomefile` : null}
                >
                    <FormInput name='filename_regex' disabled={!values.is_regex_enabled} />
                </FormGroup>
            </div>

            <FormDescription title='Scadenza' desc='Abilita la richiesta di inserimento della data di scadenza per questa tipologia di documento.' />
            <FormGroup label='' name='is_expiration_required'>
                <FormCheckbox name='is_expiration_required' label={"Abilita"} />
            </FormGroup>
        </div>
    );
};

export default DocumentTypeForm;
