import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import documentTypeService from "../../../../services/documentTypeService";
import DocumentTypeForm from "./_components/DocumentTypeForm";

const DocumentTypeCreate = () => {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
        description: Yup.string().max(800).nullable().notRequired(),
        document_category_id: Yup.object().required(),
        is_expiration_required: Yup.bool().notRequired(),
        can_be_uploaded_by_company: Yup.bool().notRequired(),
        is_regex_enabled: Yup.bool().required(),
        filename_regex: Yup.string(50)
            .nullable()
            .notRequired()
            .when("is_regex_enabled", {
                is: true,
                then: Yup.string(50).nullable().required(),
            }),
    });

    const initialValues = {
        name: "",
        description: "",
        document_category_id: "",
        is_expiration_required: false,
        can_be_uploaded_by_company: false,
        is_regex_enabled: false,
        filename_regex: "",
    };

    return (
        <PageContainer>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    documentTypeService
                        .store({
                            ...values,
                            filename_regex: values.is_regex_enabled ? values.filename_regex : null,
                            document_category_id: values.document_category_id.value,
                        })
                        .then(res => {
                            toast.push(<Notification title='Tipologia creata con successo' type='success' />);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ resetForm, isSubmitting, isValid }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Nuova tipologia documento'
                                    description='Crea una nuova tipologia documento.'
                                    loading={false}
                                    toolbar={
                                        <>
                                            <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                        </>
                                    }
                                />
                            }
                        >
                            <DocumentTypeForm />
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default DocumentTypeCreate;
