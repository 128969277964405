import api from "../configs/api.config";

const COMPANY_BASE_URL = "/companies";
const fetchAll = params => api.get(COMPANY_BASE_URL, { params }).then(res => res.data);
const fetch = id => api.get(`${COMPANY_BASE_URL}/${id}`).then(res => res.data.data);
const store = params => api.post(`${COMPANY_BASE_URL}`, params).then(res => res.data.data);
const update = (id, params) => api.put(`${COMPANY_BASE_URL}/${id}`, params).then(res => res.data.data);
const destroy = id => api.delete(`${COMPANY_BASE_URL}/${id}`);
const resetPublicPassword = id => api.post(`${COMPANY_BASE_URL}/${id}/reset-public-password`).then(res => res.data.data);

const companyService = {
    fetchAll,
    fetch,
    store,
    update,
    destroy,
    resetPublicPassword,
};

export default companyService;
