import React from "react";
import { useParams } from "react-router-dom";

import DocumentCategoryFolders from "./_components/DocumentCategoryFolders";
import DocumentCategoryNavigator from "./_components/DocumentCategoryNavigator";

const DocumentNavigator = ({ company, pastYearLimit = 0, futureYearLimit = 0, isAdmin = false }) => {
    const { cat } = useParams();

    return !!cat ? (
        <DocumentCategoryFolders isAdmin={isAdmin} companyId={company?.id} pastYearLimit={pastYearLimit} futureYearLimit={futureYearLimit} />
    ) : (
        <DocumentCategoryNavigator />
    );
};

export default DocumentNavigator;
