import React from "react";

import FormCheckboxDisplay from "../../../../../../../components/RefactoredForms/FormCheckboxDisplay";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import FormDisplay from "../../../../../../../components/RefactoredForms/FormDisplay";

const CompanyUserShow = ({ user, documentTypes = [] }) => {
    return (
        <div className='flex flex-col gap-4'>
            <div className='grid lg:grid-cols-3 gap-4'>
                <FormDisplay value={"Nome e cognome"}>{user?.name}</FormDisplay>
                <FormDisplay value={"Email"}>{user?.email}</FormDisplay>
                <FormDisplay value={"Ruolo"}>{user?.role?.name}</FormDisplay>
            </div>
            <FormDescription title={"Tipologie documento"} desc={"Seleziona le tipologie di documento che l'utente ha il permesso di consulare."} />
            {user?.company_user.can_view_all_document_types || user?.role?.id === 1 ? (
                <FormCheckboxDisplay checked={user?.company_user.can_view_all_document_types}>
                    Permesso di vedere tutti le tipologie di documento
                </FormCheckboxDisplay>
            ) : (
                <div className='flex flex-col gap-4'>
                    <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                        {documentTypes.map(dt => (
                            <FormCheckboxDisplay
                                key={dt.uuid}
                                checked={user.company_user.document_types_permitted.includes(dt.id)}
                                info={dt.description}
                            >
                                {dt.name}
                            </FormCheckboxDisplay>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyUserShow;
