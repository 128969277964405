import React from "react";
import { useSanctum } from "react-sanctum";

import { ApplicationContext } from "../../../../App";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import DocumentNavigator from "../../../../components/ui/DocumentNavigator/DocumentNavigator";
import PageHeader from "../../../../components/ui/Page/PageHeader";

const MyCompanyDocuments = () => {
    const { user } = useSanctum();

    const { currentCompany } = React.useContext(ApplicationContext);

    return (
        <PageContainer>
            <Card header={<PageHeader title='Documenti aziendali' />}>
                <DocumentNavigator
                    company={user.company}
                    isAdmin={false}
                    pastYearLimit={currentCompany?.pastYearLimit}
                    futureYearLimit={currentCompany?.futureYearLimit}
                />
            </Card>
        </PageContainer>
    );
};

export default MyCompanyDocuments;
