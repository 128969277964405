import { addYears, eachMonthOfInterval, eachYearOfInterval, endOfYear, format, startOfYear, subYears } from "date-fns";
import { it } from "date-fns/locale";

const getMonths = () =>
    eachMonthOfInterval({
        start: startOfYear(new Date()),
        end: endOfYear(new Date()),
    }).map((month, index) => ({
        value: index + 1,
        label: format(month, "MMMM", { locale: it }).toUpperCase(),
    }));

const getMonth = monthNumber => getMonths().find(month => month.value === monthNumber);

const getYears = (pastYearNumber = 1, futureYearNumber = 1) =>
    eachYearOfInterval({
        start: subYears(new Date(), pastYearNumber),
        end: addYears(new Date(), futureYearNumber),
    }).map(year => ({
        value: Number(format(year, "yyyy")),
        label: format(year, "yyyy").toString(),
    }));

const getAllYears = () =>
    eachYearOfInterval({
        start: new Date(process.env.REACT_APP_DOUCMENTS_START_YEAR, 1, 1),
        end: addYears(new Date(), process.env.REACT_APP_COMPANY_FUTURE_YEAR_LIMIT),
    }).map(year => ({
        value: Number(format(year, "yyyy")),
        label: format(year, "yyyy").toString(),
    }));

const dateHelper = {
    getMonths,
    getMonth,
    getYears,
    getAllYears,
};

export default dateHelper;
